.pf-c-data-list {
  --pf-global--Color--100: var(--pf-global--Color--dark-100);
  --pf-global--Color--200: var(--pf-global--Color--dark-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
  --pf-global--link--Color: var(--pf-global--link--Color--dark);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--light-100); }

.pf-c-data-list__item-action {
  --pf-hidden-visible--visible--Visibility: visible;
  --pf-hidden-visible--hidden--Display: none;
  --pf-hidden-visible--hidden--Visibility: hidden;
  --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
  --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility);
  display: var(--pf-hidden-visible--Display);
  visibility: var(--pf-hidden-visible--Visibility); }
  .pf-m-hidden.pf-c-data-list__item-action {
    --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
    --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
  @media screen and (min-width: 576px) {
    .pf-m-hidden-on-sm.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-sm.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }
  @media screen and (min-width: 768px) {
    .pf-m-hidden-on-md.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-md.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }
  @media screen and (min-width: 992px) {
    .pf-m-hidden-on-lg.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-lg.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }
  @media screen and (min-width: 1200px) {
    .pf-m-hidden-on-xl.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-xl.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }
  @media screen and (min-width: 1450px) {
    .pf-m-hidden-on-2xl.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-2xl.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }

.pf-c-data-list {
  --pf-c-data-list--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-data-list--BorderTopColor: var(--pf-global--BorderColor--100);
  --pf-c-data-list--BorderTopWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-data-list--sm--BorderTopWidth: var(--pf-global--spacer--sm);
  --pf-c-data-list--sm--BorderTopColor: var(--pf-global--BorderColor--300);
  --pf-c-data-list--BorderBottomColor: var(--pf-global--BorderColor--100);
  --pf-c-data-list--BorderBottomWidth: 0;
  --pf-c-data-list__item--m-expanded--before--BackgroundColor: var(--pf-global--active-color--100);
  --pf-c-data-list__item--m-selected--before--BackgroundColor: var(--pf-global--active-color--100);
  --pf-c-data-list__item--m-selected--BoxShadow: var(--pf-global--BoxShadow--sm-top), var(--pf-global--BoxShadow--sm-bottom);
  --pf-c-data-list__item--m-selectable--OutlineOffset: -0.25rem;
  --pf-c-data-list__item--m-selectable--hover--ZIndex: var(--pf-global--ZIndex--xs);
  --pf-c-data-list__item--m-selectable--hover--BoxShadow: var(--pf-global--BoxShadow--sm-top), var(--pf-global--BoxShadow--sm-bottom);
  --pf-c-data-list__item--m-selectable--focus--BoxShadow: var(--pf-global--BoxShadow--sm-top), var(--pf-global--BoxShadow--sm-bottom);
  --pf-c-data-list__item--m-selectable--active--BoxShadow: var(--pf-global--BoxShadow--sm-top), var(--pf-global--BoxShadow--sm-bottom);
  --pf-c-data-list__item--m-expanded--m-selectable--before--BackgroundColor: var(--pf-global--active-color--300);
  --pf-c-data-list__item-item--BorderTopColor: var(--pf-global--BorderColor--100);
  --pf-c-data-list__item-item--BorderTopWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-data-list__item--hover--item--BorderTopColor: var(--pf-c-data-list__item-item--BorderTopColor);
  --pf-c-data-list__item--hover--item--BorderTopWidth: var(--pf-c-data-list__item-item--BorderTopWidth);
  --pf-c-data-list__item-item--sm--BorderTopWidth: 0.5rem;
  --pf-c-data-list__item-item--sm--BorderTopColor: var(--pf-global--BorderColor--300);
  --pf-c-data-list__item--before--BackgroundColor: transparent;
  --pf-c-data-list__item--before--Width: var(--pf-global--BorderWidth--lg);
  --pf-c-data-list__item--before--Transition: var(--pf-global--Transition);
  --pf-c-data-list__item--before--ZIndex: var(--pf-global--ZIndex--xl);
  --pf-c-data-list__item--before--Top: 0;
  --pf-c-data-list__item--before--Bottom: 0;
  --pf-c-data-list__item--before--Height: initial;
  --pf-c-data-list__item-item--before--Top: calc(var(--pf-c-data-list__item-item--BorderTopWidth) * -1);
  --pf-c-data-list__item-item--before--Height: initial;
  --pf-c-data-list__item-row--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-row--md--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-data-list__item-row--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-row--md--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-data-list__item-content--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-data-list__cell--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-data-list__cell--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-data-list__cell--md--PaddingBottom: 0;
  --pf-c-data-list__cell--m-icon--MarginRight: var(--pf-global--spacer--md);
  --pf-c-data-list__cell-cell--PaddingTop: 0;
  --pf-c-data-list__cell-cell--md--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-data-list__cell-cell--MarginRight: var(--pf-global--spacer--xl);
  --pf-c-data-list__toggle--MarginLeft: calc(var(--pf-global--spacer--sm) * -1);
  --pf-c-data-list__toggle--MarginTop: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-data-list__toggle-icon--Transition: .2s ease-in 0s;
  --pf-c-data-list__item--m-expanded__toggle--c-button-icon--Transform: rotate(90deg);
  --pf-c-data-list__item-control--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-control--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-control--MarginRight: var(--pf-global--spacer--xl);
  --pf-c-data-list__item-control--md--MarginRight: var(--pf-global--spacer--md);
  --pf-c-data-list__item-control--not-last-child--MarginRight: var(--pf-global--spacer--md);
  --pf-c-data-list__item-action--Display: flex;
  --pf-c-data-list__item-action--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-action--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-action--MarginLeft: var(--pf-global--spacer--xl);
  --pf-c-data-list__item-action--md--MarginLeft: var(--pf-global--spacer--md);
  --pf-c-data-list__item-action--not-last-child--MarginRight: var(--pf-global--spacer--md);
  --pf-c-data-list__item-action--not-last-child--lg--MarginBottom: var(--pf-global--spacer--md);
  --pf-c-data-list__action--MarginTop: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-data-list__expandable-content--BorderTopColor: var(--pf-global--BorderColor--100);
  --pf-c-data-list__expandable-content--MarginRight: calc(var(--pf-c-data-list__expandable-content-body--PaddingRight) * -1);
  --pf-c-data-list__expandable-content--MarginLeft: calc(var(--pf-c-data-list__expandable-content-body--PaddingLeft) * -1);
  --pf-c-data-list__expandable-content--BoxShadow: var(--pf-global--BoxShadow--md-bottom);
  --pf-c-data-list__expandable-content--md--MaxHeight: 37.5rem;
  --pf-c-data-list__expandable-content--before--Top: calc(var(--pf-c-data-list__item-item--BorderTopWidth) * -1);
  --pf-c-data-list__item--m-expanded__expandable-content--BorderTopWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-data-list__expandable-content-body--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-data-list__expandable-content-body--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-data-list__expandable-content-body--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-data-list__expandable-content-body--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-data-list__expandable-content-body--md--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-data-list__expandable-content-body--md--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-data-list__expandable-content-body--md--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-data-list__expandable-content-body--md--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-data-list--m-compact__cell--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__cell--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__cell--md--PaddingBottom: 0;
  --pf-c-data-list--m-compact__cell-cell--PaddingTop: 0;
  --pf-c-data-list--m-compact__cell-cell--md--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__cell-cell--MarginRight: var(--pf-global--spacer--md);
  --pf-c-data-list--m-compact__item-control--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__item-control--PaddingBottom: 0;
  --pf-c-data-list--m-compact__item-control--MarginRight: var(--pf-global--spacer--md);
  --pf-c-data-list--m-compact__item-action--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__item-action--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__item-action--MarginLeft: var(--pf-global--spacer--md);
  --pf-c-data-list--m-compact__item-content--PaddingBottom: var(--pf-global--spacer--sm);
  color: var(--pf-global--Color--100);
  list-style-type: disc;
  border-top: var(--pf-c-data-list--BorderTopWidth) solid var(--pf-c-data-list--BorderTopColor);
  border-bottom: var(--pf-c-data-list--BorderBottomWidth) solid var(--pf-c-data-list--BorderBottomColor); }
  @media screen and (max-width: 576px) {
    .pf-c-data-list {
      --pf-c-data-list--BorderTopColor: var(--pf-c-data-list--sm--BorderTopColor);
      --pf-c-data-list--BorderTopWidth: var(--pf-c-data-list--sm--BorderTopWidth); } }
  @media screen and (max-width: 576px) {
    .pf-c-data-list {
      --pf-c-data-list__item-item--BorderTopWidth: var(--pf-c-data-list__item-item--sm--BorderTopWidth);
      --pf-c-data-list__item-item--BorderTopColor: var(--pf-c-data-list__item-item--sm--BorderTopColor); } }
  @media screen and (max-width: 768px) {
    .pf-c-data-list {
      --pf-c-data-list__item-row--PaddingRight: var(--pf-c-data-list__item-row--md--PaddingRight);
      --pf-c-data-list__item-row--PaddingLeft: var(--pf-c-data-list__item-row--md--PaddingLeft); } }
  @media screen and (min-width: 768px) {
    .pf-c-data-list {
      --pf-c-data-list__cell-cell--PaddingTop: var(--pf-c-data-list__cell-cell--md--PaddingTop);
      --pf-c-data-list__cell--PaddingBottom: var(--pf-c-data-list__cell--md--PaddingBottom); } }
  @media screen and (max-width: 768px) {
    .pf-c-data-list {
      --pf-c-data-list__item-control--MarginRight: var(--pf-c-data-list__item-control--md--MarginRight); } }
  @media screen and (max-width: 768px) {
    .pf-c-data-list {
      --pf-c-data-list__item-action--MarginLeft: var(--pf-c-data-list__item-action--md--MarginLeft); } }
  @media screen and (max-width: 768px) {
    .pf-c-data-list {
      --pf-c-data-list__expandable-content-body--PaddingTop: var(--pf-c-data-list__expandable-content-body--md--PaddingTop);
      --pf-c-data-list__expandable-content-body--PaddingRight: var(--pf-c-data-list__expandable-content-body--md--PaddingRight);
      --pf-c-data-list__expandable-content-body--PaddingBottom: var(--pf-c-data-list__expandable-content-body--md--PaddingBottom);
      --pf-c-data-list__expandable-content-body--PaddingLeft: var(--pf-c-data-list__expandable-content-body--md--PaddingLeft); } }
  @media screen and (min-width: 768px) {
    .pf-c-data-list {
      --pf-c-data-list--m-compact__cell--PaddingBottom: var(--pf-c-data-list--m-compact__cell--md--PaddingBottom);
      --pf-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-c-data-list--m-compact__cell-cell--md--PaddingTop); } }
  .pf-c-data-list.pf-m-compact {
    --pf-c-data-list__cell--PaddingTop: var(--pf-c-data-list--m-compact__cell--PaddingTop);
    --pf-c-data-list__cell--PaddingBottom: var(--pf-c-data-list--m-compact__cell--PaddingBottom);
    --pf-c-data-list__cell-cell--MarginRight: var(--pf-c-data-list--m-compact__cell-cell--MarginRight);
    --pf-c-data-list__cell-cell--PaddingTop: var(--pf-c-data-list--m-compact__cell-cell--PaddingTop);
    --pf-c-data-list__item-action--MarginLeft: var(--pf-c-data-list--m-compact__item-action--MarginLeft);
    --pf-c-data-list__item-action--PaddingTop: var(--pf-c-data-list--m-compact__item-action--PaddingTop);
    --pf-c-data-list__item-action--PaddingBottom: var(--pf-c-data-list--m-compact__item-action--PaddingBottom);
    --pf-c-data-list__item-control--MarginRight: var(--pf-c-data-list--m-compact__item-control--MarginRight);
    --pf-c-data-list__item-control--PaddingTop: var(--pf-c-data-list--m-compact__item-control--PaddingTop);
    --pf-c-data-list__item-control--PaddingBottom: var(--pf-c-data-list--m-compact__item-control--PaddingBottom);
    --pf-c-data-list__item-content--PaddingBottom: var(--pf-c-data-list--m-compact__item-content--PaddingBottom); }

.pf-c-data-list__item {
  display: flex;
  flex-direction: column;
  background-color: var(--pf-c-data-list--BackgroundColor); }
  @media (min-width: 576px) {
    .pf-c-data-list__item {
      --pf-c-data-list__item--before--Top: var(--pf-c-data-list__item-item--before--Top); } }
  .pf-c-data-list__item:not(.pf-m-expanded) {
    border-bottom: var(--pf-c-data-list__item-item--BorderTopWidth) solid var(--pf-c-data-list__item-item--BorderTopColor); }
    .pf-c-data-list__item:not(.pf-m-expanded):not(.pf-m-selected):not(:last-child).pf-m-selectable:hover {
      --pf-c-data-list__item-item--BorderTopWidth: 0; }
      .pf-c-data-list__item:not(.pf-m-expanded):not(.pf-m-selected):not(:last-child).pf-m-selectable:hover + .pf-c-data-list__item {
        border-top: var(--pf-c-data-list__item--hover--item--BorderTopWidth) solid var(--pf-c-data-list__item--hover--item--BorderTopColor); }
  .pf-c-data-list__item.pf-m-selectable {
    cursor: pointer;
    outline-offset: var(--pf-c-data-list__item--m-selectable--OutlineOffset); }
    .pf-c-data-list__item.pf-m-selectable:hover, .pf-c-data-list__item.pf-m-selectable:focus {
      position: relative;
      z-index: var(--pf-c-data-list__item--m-selectable--hover--ZIndex); }
    .pf-c-data-list__item.pf-m-selectable:hover {
      box-shadow: var(--pf-c-data-list__item--m-selectable--hover--BoxShadow); }
    .pf-c-data-list__item.pf-m-selectable:focus {
      box-shadow: var(--pf-c-data-list__item--m-selectable--focus--BoxShadow); }
    .pf-c-data-list__item.pf-m-selectable:active {
      box-shadow: var(--pf-c-data-list__item--m-selectable--active--BoxShadow); }
  .pf-c-data-list__item.pf-m-selected {
    --pf-c-data-list__item--before--BackgroundColor: var(--pf-c-data-list__item--m-selected--before--BackgroundColor);
    position: relative;
    box-shadow: var(--pf-c-data-list__item--m-selected--BoxShadow); }
  .pf-c-data-list__item.pf-m-expanded {
    --pf-c-data-list__item--before--BackgroundColor: var(--pf-c-data-list__item--m-expanded--before--BackgroundColor); }
    .pf-c-data-list__item.pf-m-expanded.pf-m-selectable:not(.pf-m-selected) {
      --pf-c-data-list__item--before--BackgroundColor: var(--pf-c-data-list__item--m-expanded--m-selectable--before--BackgroundColor); }

.pf-c-data-list__item-row {
  display: flex;
  flex-wrap: nowrap;
  padding-right: var(--pf-c-data-list__item-row--PaddingRight);
  padding-left: var(--pf-c-data-list__item-row--PaddingLeft); }

.pf-c-data-list__item-control {
  display: flex;
  flex-wrap: nowrap;
  padding-top: var(--pf-c-data-list__item-control--PaddingTop);
  padding-bottom: var(--pf-c-data-list__item-control--PaddingBottom);
  margin-right: var(--pf-c-data-list__item-control--MarginRight); }
  .pf-c-data-list__item-control > *:not(:last-child) {
    margin-right: var(--pf-c-data-list__item-control--not-last-child--MarginRight); }

.pf-c-data-list__item-action {
  --pf-hidden-visible--visible--Display: var(--pf-c-data-list__item-action--Display);
  flex-wrap: nowrap;
  align-items: flex-start;
  align-content: flex-start;
  padding-top: var(--pf-c-data-list__item-action--PaddingTop);
  padding-bottom: var(--pf-c-data-list__item-action--PaddingBottom);
  margin-left: var(--pf-c-data-list__item-action--MarginLeft); }
  .pf-c-data-list__item-action > *:not(:last-child) {
    margin-right: var(--pf-c-data-list__item-action--not-last-child--MarginRight); }
    @media screen and (max-width: 992px) {
      .pf-c-data-list__item-action > *:not(:last-child) {
        margin-bottom: var(--pf-c-data-list__item-action--not-last-child--lg--MarginBottom); } }
  @media screen and (max-width: 992px) {
    .pf-c-data-list__item-action {
      flex-wrap: wrap; } }
  .pf-c-data-list__item-action .pf-c-data-list__action {
    margin-top: var(--pf-c-data-list__action--MarginTop); }

.pf-c-data-list__toggle {
  margin-top: var(--pf-c-data-list__toggle--MarginTop);
  margin-left: var(--pf-c-data-list__toggle--MarginLeft); }
  .pf-c-data-list__toggle .pf-c-button > * {
    pointer-events: none;
    transition: var(--pf-c-data-list__toggle-icon--Transition); }
    .pf-c-data-list__item.pf-m-expanded .pf-c-data-list__toggle .pf-c-button > * {
      transform: var(--pf-c-data-list__item--m-expanded__toggle--c-button-icon--Transform); }

.pf-c-data-list__item-row,
.pf-c-data-list__expandable-content {
  position: relative; }
  .pf-c-data-list__item-row::before,
  .pf-c-data-list__expandable-content::before {
    --pf-c-data-list__item--before--Height: var(--pf-c-data-list__item-item--before--Height);
    position: absolute;
    top: var(--pf-c-data-list__item--before--Top);
    bottom: var(--pf-c-data-list__item--before--Bottom);
    left: 0;
    width: var(--pf-c-data-list__item--before--Width);
    height: var(--pf-c-data-list__item--before--Height);
    content: "";
    background-color: var(--pf-c-data-list__item--before--BackgroundColor);
    transition: var(--pf-c-data-list__item--before--Transition); }

.pf-c-data-list__item-content {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  padding-bottom: var(--pf-c-data-list__item-content--PaddingBottom); }
  @media screen and (max-width: 768px) {
    .pf-c-data-list__item-content {
      display: grid;
      width: 100%;
      grid-template-columns: auto 1fr;
      padding-bottom: 0; } }

.pf-c-data-list__cell {
  flex: 1;
  padding-top: var(--pf-c-data-list__cell--PaddingTop);
  grid-column: 1 / -1;
  padding-bottom: var(--pf-c-data-list__cell--PaddingBottom); }
  @media screen and (min-width: 768px) {
    .pf-c-data-list__cell:not(:last-child) {
      margin-right: var(--pf-c-data-list__cell-cell--MarginRight); } }
  .pf-c-data-list__cell + .pf-c-data-list__cell {
    flex: 1 0 100%;
    order: 1;
    padding-top: var(--pf-c-data-list__cell-cell--PaddingTop); }
    @media screen and (min-width: 768px) {
      .pf-c-data-list__cell + .pf-c-data-list__cell {
        flex: 1;
        order: initial; } }
  .pf-c-data-list__cell.pf-m-icon {
    flex-grow: 0;
    margin-right: var(--pf-c-data-list__cell--m-icon--MarginRight);
    grid-column: 1 / 2; }
  .pf-c-data-list__cell.pf-m-no-fill {
    flex-grow: 0; }
  .pf-c-data-list__cell.pf-m-align-right {
    margin-left: auto; }
    @media screen and (max-width: 768px) {
      .pf-c-data-list__cell.pf-m-align-right {
        margin-left: 0; } }
  .pf-c-data-list__cell.pf-m-icon + .pf-c-data-list__cell {
    grid-column: 2 / 3;
    padding-top: var(--pf-c-data-list__cell--PaddingTop); }
  .pf-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2; }
  .pf-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3; }
  .pf-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4; }
  .pf-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5; }

.pf-c-data-list__expandable-content {
  box-shadow: var(--pf-c-data-list__expandable-content--BoxShadow); }
  .pf-c-data-list__expandable-content .pf-c-data-list__expandable-content-body {
    padding: var(--pf-c-data-list__expandable-content-body--PaddingTop) var(--pf-c-data-list__expandable-content-body--PaddingRight) var(--pf-c-data-list__expandable-content-body--PaddingBottom) var(--pf-c-data-list__expandable-content-body--PaddingLeft);
    border-top: var(--pf-c-data-list__item--m-expanded__expandable-content--BorderTopWidth) solid var(--pf-c-data-list__expandable-content--BorderTopColor); }
    .pf-c-data-list__expandable-content .pf-c-data-list__expandable-content-body.pf-m-no-padding {
      padding: 0; }
  @media screen and (max-width: 768px) {
    .pf-c-data-list__expandable-content {
      max-height: var(--pf-c-data-list__expandable-content--md--MaxHeight);
      overflow-y: auto; } }
