.pf-l-split {
  --pf-l-split--m-gutter--MarginRight: var(--pf-global--gutter);
  --pf-l-split--m-gutter--md--MarginRight: var(--pf-global--gutter--md);
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0; }
  @media screen and (max-width: 768px) {
    .pf-l-split {
      --pf-l-split--m-gutter--MarginRight: var(--pf-l-split--m-gutter--md--MarginRight); } }

.pf-l-split__item.pf-m-fill {
  flex-grow: 1; }

.pf-l-split.pf-m-gutter > *:not(:last-child) {
  margin-right: var(--pf-l-split--m-gutter--MarginRight); }
