/**
Need to unset tippy default styles
Also for enableFlip, need to make arrow aware of parent x-placement attribute in order to work
*/
.pf-tooltip-theme.tippy-tooltip,
.pf-popover-theme.tippy-tooltip {
  background-color: unset;
  font-size: unset;
  color: unset;
  border-radius: unset;
  -webkit-font-smoothing: unset;
  text-align: unset;
  padding: unset;
  cursor: text;

  --pf-c-tooltip__content--BackgroundColor: var(--pf-global--BackgroundColor--dark-100);
  --pf-c-tooltip__arrow--Width: var(--pf-global--arrow--width);
  --pf-c-tooltip__arrow--Height: var(--pf-global--arrow--width);

  --pf-c-popover__arrow--Width: var(--pf-global--arrow--width-lg);
  --pf-c-popover__arrow--Height: var(--pf-global--arrow--width-lg);
  --pf-c-popover__arrow--BoxShadow: var(--pf-global--BoxShadow--md);
  --pf-c-popover__arrow--BackgroundColor: var(--pf-global--BackgroundColor--100);
}
.pf-tooltip-theme .pf-c-tooltip, 
.pf-popover-theme .pf-c-popover {
  max-width: unset;
}

.tippy-popper {
  line-height: unset !important;
}

.tippy-popper[x-placement^=top] .pf-tooltip-theme .tippy-arrow,
.tippy-popper[x-placement^=bottom] .pf-tooltip-theme .tippy-arrow,
.tippy-popper[x-placement^=left] .pf-tooltip-theme .tippy-arrow,
.tippy-popper[x-placement^=right] .pf-tooltip-theme .tippy-arrow {
  border: unset;

  width: var(--pf-c-tooltip__arrow--Width);
  height: var(--pf-c-tooltip__arrow--Height);
  pointer-events: none;
  background-color: var(--pf-c-tooltip__content--BackgroundColor);

  transform: rotate(45deg);
  transform-origin: center;
}

.tippy-popper[x-placement^=top] .pf-popover-theme .tippy-arrow,
.tippy-popper[x-placement^=bottom] .pf-popover-theme .tippy-arrow,
.tippy-popper[x-placement^=left] .pf-popover-theme .tippy-arrow,
.tippy-popper[x-placement^=right] .pf-popover-theme .tippy-arrow {
  border: unset;

  width: var(--pf-c-popover__arrow--Width);
  height: var(--pf-c-popover__arrow--Height);
  pointer-events: none;
  background-color: var(--pf-c-popover__arrow--BackgroundColor);
  box-shadow: var(--pf-c-popover__arrow--BoxShadow);

  transform: rotate(45deg);
  transform-origin: center;
}
.tippy-popper[x-placement^=top] .pf-popover-theme .tippy-arrow {
  bottom: -13px;
}
.tippy-popper[x-placement^=bottom] .pf-popover-theme .tippy-arrow {
  top: -13px;
}
.tippy-popper[x-placement^=left] .pf-popover-theme .tippy-arrow {
  right: -13px;
}
.tippy-popper[x-placement^=right] .pf-popover-theme .tippy-arrow {
  left: -13px;
}